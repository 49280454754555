/**
 * Fonctions utilitaires pour manipuler le dom
 * @module lib/utils/dom/index
 */
import { MathfieldElement } from 'mathlive'
import Modal from 'src/lib/entities/dom/Modal'
import { setStyle } from 'src/lib/utils/css'
import type { ObjOfString } from 'src/lib/utils/object'
import { isPlainObjectNotEmpty } from 'src/lib/utils/object'
import { isValidId } from 'src/lib/utils/string'
import type { EltOptions, EltPropsPartial, HtmlElt, RichEltOptions, TagNameHtml } from './dom'
import { CheckBoxesChoices, Elt, TagName } from './dom'

/** Les tags que l’on autorise dans un contenu riche */
export const contentTagsAllowed = ['a', 'br', 'div', 'em', 'h2', 'h3', 'h4', 'li', 'p', 'span', 'strong', 'u', 'ul']

/** type d’un tag autorisé dans addRichElt */
export type ContentTagsAllowed = TagNameHtml & typeof contentTagsAllowed[number] // faut & pour que ts infère que c’est forcément un tag html

/**
 * délai de chargement par défaut d’un js
 */
const jsTimeoutDefault = 30

const ge = (id: string) => document.getElementById(id)

/**
 * Ajoute des checkboxes dans le container
 * @param container
 * @param choices
 * @returns Un objet avec les inputs créés (indexés par leur name, ceux de l’objet choices)
 */
export function addCheckBoxes (container: HTMLElement, choices: CheckBoxesChoices): Record<string, HTMLInputElement> {
  const inputs: Record<string, HTMLInputElement> = {}
  const style = {
    marginRight: '15px',
    marginLeft: '15px'
  }
  for (const [name, { checked = false, label, value }] of Object.entries(choices)) {
    const labelElt = addElement(container, 'label')
    const attrs = { type: 'checkbox', name, value }
    const input = addElement(labelElt, 'input', { attrs, style, checked })
    addTextContent(labelElt, label)
    inputs[name] = input
  }
  return inputs
}

/**
 * Ajoute une css dans le <head> de la page
 * @param file Chemin du fichier css (mis dans href tel quel)
 */
export function addCss (file: string): void {
  const head = window.document.getElementsByTagName('head')[0]
  if (head == null) throw Error('pas de tag head dans le document courant')
  const links = head.getElementsByTagName('link')
  if (Array.from(links).some(({ href }) => href === file)) {
    console.warn(Error(`${file} était déjà présent dans le tag head, on ne l’ajoute pas`))
  } else {
    addElement(head, 'link', {
      rel: 'stylesheet',
      type: 'text/css',
      href: file
    })
  }
}

/**
 * Ajoute un élément dans container
 * @param container
 * @param tag
 * @param options
 */
export function addElement<Tag extends TagName> (container: HTMLElement, tag: Tag, options?: EltOptions<Tag>): Elt<Tag> {
  // pour compatibilité ascendante, on accepte une string en options
  if (typeof options === 'string') {
    // faut lui coller du `as` car options n’est pas sensé être une string
    options = { content: options } as EltOptions<Tag>
  } else if (options == null) {
    options = {}
  }
  // on prend les options attrs et content à part, tout le reste c’est des props
  const { attrs = {}, content, ...props } = options

  // il faut préciser pour chaque cas le `as Elt<typeof tag>`
  // sinon ts râle sur le return elt en disant par ex
  // Type 'MathfieldElement' is not assignable to type 'Elt<Tag>'
  // même si `addElement(document.body, 'math-field')` est bien vu comme un MathfieldElement
  const elt = tag === 'svg'
    ? document.createElementNS('http://www.w3.org/2000/svg', 'svg') as Elt<typeof tag>
    : tag === 'math-field'
      ? new MathfieldElement() as Elt<typeof tag>
      : document.createElement(tag) as Elt<typeof tag>
  if (isPlainObjectNotEmpty(attrs)) setAttrs(elt, attrs)
  if (isPlainObjectNotEmpty(props)) setProps(elt, props as EltPropsPartial<Tag>)
  if (content != null) addTextContent(elt, content)
  container.appendChild(elt)

  return elt
}

/**
 * Ajoute un tag dans container, avec un contenu qui peut contenir d’autres tags
 * @param container
 * @param options
 * @return Le HtmlElement créé (de type options.tag)
 */
export function addRichElt<Tag extends ContentTagsAllowed> (container: HTMLElement, options: RichEltOptions<Tag>): HtmlElt<Tag> {
  let {
    tag,
    attrs = {},
    content = '',
    contentOptions = {},
    props = {}
  } = options
  // tsc nous garanti qu’on passera pas dans ce if mais on peut être appelé par du js
  if (!contentTagsAllowed.includes(tag)) {
    console.error(Error(`${tag} n’est pas un tag autorisé dans du contenu enrichi`))
    tag = 'span' as Tag
    content = `<${tag}>${content}</${tag}>`
  }
  const elt: HtmlElt<Tag> = document.createElement(tag)
  // le même avec un type HTMLElement, car TS n’infère pas qu’un HtmlElt<T> est forcément un HTMLElement si T est un tag connu (c’est un HtmlElementTagNameMap[T])
  const htmlElt: HTMLElement = elt as HTMLElement
  if (isPlainObjectNotEmpty(attrs)) setAttrs(elt as Elt<Tag>, attrs as ObjOfString)
  if (isPlainObjectNotEmpty(props)) setProps(elt as Elt<Tag>, props as EltPropsPartial<Tag>)
  const regExp = /^(.*?)%\{([a-zA-Z0-9]+)\}(.*)$/

  // tant qu’il y a du rich content, on passe en revue tous les %{truc}
  let chunks
  // eslint-disable-next-line no-cond-assign
  while (chunks = regExp.exec(content)) {
    const [, start, key, rest] = chunks
    if (start) addTextContent(htmlElt, start as string)
    const keyOpts = contentOptions[key as string]
    if (keyOpts?.tag == null) {
      // on ignore ce %{truc} sans contentOptions.truc.tag, ça affichera %{truc}
      console.error(Error(`Options incomplètes pour la partie de contenu %{${key}} dans ${content}`), contentOptions)
      addTextContent(htmlElt, `%{${key}}`)
    } else {
      // on a trouvé du %{key} et ses options
      addRichElt(htmlElt, keyOpts)
    }
    content = rest ?? ''
  }
  if (content) addTextContent(elt, content)

  container.appendChild(elt)

  return elt
}

/**
 * Ajoute du texte dans un élément (un TextNode, ou plusieurs séparés par des &ltbr> si y’a plusieurs lignes séparées par des \n dans content)
 * @param container Le conteneur dans lequel mettre le texte
 * @param content Le texte à insérer
 */
export function addTextContent (container: HTMLElement | SVGElement, content: string | number): void {
  if (typeof content === 'number') content = String(content)
  if (typeof content === 'string') {
    if (content.includes('\n')) {
      let isFirst = true
      for (const line of content.split('\n')) {
        if (!isFirst) container.appendChild(document.createElement('br'))
        isFirst = false
        container.appendChild(getTextNode(line))
      }
    } else {
      container.appendChild(getTextNode(content))
    }
  }
}

/**
 * Ajoute un tag video dans le conteneur
 * @param conteneur
 * @param options Il faut passer au moins webm ou mp4
 * @param [options.webm] url du fichier webm
 * @param [options.mp4] url du fichier mp4
 * @param [options.height]
 * @param [options.width]
 */
export function ajouteVideo (conteneur: HTMLElement, { webm, mp4, height, width }: {
  webm?: string,
  mp4?: string,
  height?: string,
  width?: string
}): void {
  if (!isDomElement(conteneur, { htmlOnly: true })) throw Error('conteneur invalide')
  if (mp4 == null && webm == null) throw Error('Il faut passer au moins une vidéo source')
  const props: { style?: Record<string, string> } = {}
  if (height != null && width != null) props.style = { height, width }
  const video = addElement(conteneur, 'video', props)
  video.setAttribute('controls', 'controls')
  if (mp4 != null) addElement(video, 'source', { attrs: { src: mp4, type: 'video/mp4' } })
  if (webm != null) addElement(video, 'source', { attrs: { src: webm, type: 'video/webm;codecs=vp8,vorbis' } })
}

/**
 * Retire du dom les éléments html passés en argument
 * @param elts Un ou des élément(s) à détruire, on peut passer autant d’arguments que l’on veut (pour détruire plusieurs elts en un seul appel)
 */
export function destroy (...elts: Array<Element | null | undefined>): void {
  for (const elt of elts) {
    if (elt == null) continue // on tolère des trucs qui n’existent déjà plus
    if (isDomElement(elt, { warnIfNotElt: true })) {
      if (elt.parentNode == null) {
        empty(elt)
        console.error(Error('L’élément est bien un HTMLElement mais il n’a pas de parentNode, on le vide sans le détruire #' + elt.id), elt)
        return
      }
      elt.parentNode.removeChild(elt)
    }
  }
}

/**
 * Vide chaque Element (html ou svg) passé en paramètre de tout son contenu.
 * On peut passer autant de paramètres que l’on veut, les null|undefined seront ignorés silencieusement
 * @param elts
 */
export function empty (...elts: Element[]): void {
  for (const elt of elts) {
    if (elt == null) continue
    while (elt?.lastChild != null) elt.removeChild(elt.lastChild)
  }
}

/**
 * Donne le focus à l’élément (dans un setTimeout immédiat, au cas où y’aurait du rendu en cours, utile pour mathlive)
 * Ne fait rien si elt n’existe pas ou plus.
 * @param elt
 */
export function focusIfExists (elt: HTMLElement | string | null): void {
  setTimeout(() => {
    // on râle pas si elt n’existe pas ou plus
    if (typeof elt === 'string') elt = ge(elt)
    // la ligne suivante devrait éviter de donner le focus à un élément dont on aurait toujours la ref mais qui aurait été viré du DOM
    if (!elt || !document.body.contains(elt)) return
    elt.focus()
  })
}

/**
 * Retourne le premier parent ayant la classe css demandée
 * @param elt
 * @param className
 * @param strict Passer true pour planter si on n’en trouve pas (sinon ça retournerait null)
 * @throws {Error} si strict et qu'il n'y a aucun parent
 */
export function getFirstParent (elt: HTMLElement, className: string, { strict = false } = {}): typeof strict extends true ? HTMLElement : (HTMLElement | null) {
  let el:HTMLElement|null = elt
  while (el?.classList != null && !el?.classList.contains(className)) el = el.parentElement
  if (strict && el == null) throw Error(`Impossible de trouver un parent .${className}`)
  return el
}

/**
 * Retourne le premier parent ayant le tag demandé
 * @param elt
 * @param tagName
 * @param strict Passer true pour planter si on n’en trouve pas (sinon ça retourne l’élément fourni)
 */
export function getFirstParentByTag (elt: HTMLElement, tagName: string, { strict = false } = {}): HTMLElement | null {
  tagName = tagName.toUpperCase()
  let el: any = elt // faut un type any sur notre variable intermédiaire pour pouvoir y affecter parentNode ET tester la présence éventuelle de classList
  while (el != null && el.tagName !== tagName) el = el.parentElement
  if (strict && (el == null)) throw Error(`Impossible de trouver un parent <${tagName}>`)
  return el
}

/**
 * Retourne un id inutilisé dans le dom (sous la forme prefixe + nb)
 * @param [prefixe=id]
 * @param [preserve] Passer true pour que prefixe soit renvoyé tel quel si l’id est libre (et sinon les suffixes demarreront à 2)
 */
export function getNewId (prefixe = 'id', preserve = false): string {
  if (!isValidId(prefixe)) throw Error(`prefixe invalide pour un id du DOM (${prefixe})`)
  let i = 0
  if (preserve) {
    // le premier sera sans suffixe et les suivants auront un suffixe qui démarrera à 2
    if (document.getElementById(prefixe) == null) return prefixe
    i = 2
  }
  const max = 50000
  while ((document.getElementById(`${prefixe}_${i}`) != null) && i < max) i++
  const id = (i < max) ? `${prefixe}_${i}` : ''
  if (id.length === 0) console.error(Error(`Il y a déjà plus de ${max} éléments préfixés par ${prefixe} => on arrête là (aucun id généré)`))
  // si on a demandé à préserver l’id et qu’on a pas pu le faire, on le signale
  if (preserve) console.error(Error(`l’id ${prefixe} existait déjà, il a été remplacé par "${id}"`))
  return id
}

/**
 * Retourne un élément Text (sorti de document.createTextNode) prêt à être inséré avec appendChild
 * Les entités html &(lt|gt|amp|nbsp|dollar); sont gérées, &#xx; aussi (même s’il vaudrait mieux l’écrire directement avec du \uXXXX),
 * mais s’il y a du code html il sera affiché tel quel.
 * Pour les caractères spéciaux voir {@link https://html.spec.whatwg.org/multipage/named-characters.html}
 * @param text
 * @return {Text}
 */
export function getTextNode (text: string): Text {
  if (text.includes('&')) {
    text = text
      .replace(/&lt;/g, '<')
      .replace(/&le;/g, '≤')
      .replace(/&gt;/g, '>')
      .replace(/&ge;/g, '≥')
      .replace(/&amp;/g, '&')
      .replace(/&dollar;/g, '$')
      // ce code fonctionne avec chrome et firefox (ça coupe pas sur cet espace)
      .replace(/&nbsp;/g, '\u00a0')
      // on remplace tous les &#xx; par leur entité utf8
      .replace(/&#[0-9]+;/g, s => String.fromCharCode(Number(s.substring(2, s.length - 1))))
  }
  return document.createTextNode(text)
}

/**
 * Retourne true si elt est bien un Element de type tag (HTML ou SVG ou custom element)
 * Très utile pour le narrowing typescript, par ex
 * ```js
 * if (isElt(elt, 'a')) {
 *   elt.href = '…'
 *   // ^-- ts ne râle pas car ici elt est bien vu comme un HTMLLinkElement
 * }
 * ```
 */
export function isElt<Tag extends TagName> (elt: unknown, tag: Tag): elt is Elt<Tag> {
  if (!elt || typeof elt !== 'object') return false
  return 'tagName' in elt && elt.tagName === tag.toUpperCase()
}

/**
 * Retourne true si elt hérite de HTMLElement (HTMLDivElement, HTMLLinkElement, …)
 * et figure dans le DOM
 * (utiliser les options de isDomElement pour savoir si c’est un HTMLElement qui n’appartient pas forcément au DOM)
 */
export function isHtmlElement (elt: unknown): elt is HTMLElement {
  // on regarde le cast en string, qui retourne par exemple [object HTMLDivElement]
  // important de prendre Object.prototype.toString et pas String (pour un tag <a> ça donne pas la même chose)
  const type = Object.prototype.toString.call(elt)
  return /^\[object HTML[a-zA-Z]*Element]$/.test(type) && document.body.contains(elt as Element)
}

/**
 * Retourne true si elt hérite de SVGElement (SVGLineElement, …)
 * et figure dans le DOM
 * (utiliser les options de isDomElement pour savoir si c’est un SVGElement qui n’appartient pas forcément au DOM)
 */
export function isSvgElement (elt: Element): elt is SVGElement {
  // on regarde le cast en string, qui retourne par exemple [object HTMLDivElement]
  // important de prendre Object.prototype.toString et pas String (pour un tag <a> ça donne pas la même chose)
  const type = Object.prototype.toString.call(elt)
  return /^\[object SVG[a-zA-Z]*Element]$/.test(type) && document.body.contains(elt as Element)
}

// @todo affiner le type de retour ci-dessous
// KO avec
// 1)
// typeof htmlOnly extends true
//   ? (elt is HTMLElement)
//   : typeof svgOnly extends true
//     ? (typeof elt extends SVGElement ? true : false)
//     : (typeof elt extends Element ? true : false)
// 2)
// typeof htmlOnly extends true
//   ? (typeof elt extends HTMLElement ? true : false)
//   : typeof svgOnly extends true
//     ? (typeof elt extends SVGElement ? true : false)
//     : (typeof elt extends Element ? true : false)

/**
 * Retourne true si elt est un HTMLElement ou un SVGElement enfant de body
 * @param elt
 * @param [options]
 * @param [options.htmlOnly=false] Passer true pour autoriser que les HTMLElement)
 * @param [options.svgOnly=false] Passer true pour n’autoriser que les SVGElement
 * @param [options.warnIfNotElt=false] Passer true pour que ça ajoute une erreur en console si elt n’était pas un HTMLElement|SVGElement
 * @param [options.mustBeInDom=false] Passer true pour que ça vérifie aussi que l’élément est bien dans le DOM
 * @param [options.warnIfNotInDom=false] Passer true pour que ça ajoute une erreur en console si elt n’était pas dans le DOM
 */
export function isDomElement (elt: unknown, {
  htmlOnly = false,
  svgOnly = false,
  warnIfNotElt = false,
  mustBeInDom = false,
  warnIfNotInDom = false
} = {}): elt is Element {
  // on regarde le cast en string, qui retourne par exemple [object HTMLDivElement]
  // important de prendre Object.prototype.toString et pas String (pour un tag <a> ça donne pas la même chose)
  const type = Object.prototype.toString.call(elt)
  const regExp = svgOnly
    ? /^\[object SVG[a-zA-Z]*Element]$/
    : htmlOnly
      ? /^\[object HTML[a-zA-Z]*Element]$/
      : /^\[object (HTML|SVG)[a-zA-Z]*Element]$/
  if (regExp.test(type)) {
    if (!mustBeInDom) return true // pas besoin de vérifier
    if (document.body.contains(elt as Element)) return true
    if (warnIfNotInDom) console.error(Error(`élément ${type} valide mais pas dans le DOM`), elt)
    return false
  }
  if (warnIfNotElt) console.error(Error(`élément invalide ${type}`), elt)
  return false
}

/**
 * Ajoute un js à la fin du body et appelle la callback quand il est chargé
 * @param {string}  file Chemin du fichier jss (mis dans src tel quel)
 * @param [options]
 * @param [options.timeout=30] en s
 * @param [options.type=text/javascript] passer "module" pour que ce soit mis dans le tag &lt;script>
 * @return {Promise<void>} Une promesse résolue quand le js est chargé (ou rejetée en cas de timeout)
 */
export async function loadJs (file: string, { timeout = jsTimeoutDefault, type = 'text/javascript' } = {}): Promise<void> {
  return await new Promise((resolve, reject) => {
    function onLoad (): void {
      clearTimeout(timerId)
      script.removeEventListener('load', onLoad)
      resolve()
    }

    const timerId: any = setTimeout(() => reject(Error(`timeout (${timeout}s) pour ${file}`)), timeout * 1000)
    const script: HTMLScriptElement = addElement(document.body, 'script', { attrs: { type } })
    // pour que ça marche mieux partout, il paraît qu’il vaut mieux mettre le listener onload après avoir mis l’élément dans le dom
    script.addEventListener('load', onLoad)
    // et ensuite indiquer le fichier à charger
    script.src = file
  })
}

interface NormalizedContainers {
  mainContainer: HTMLDivElement
  errorsContainer: HTMLDivElement
}

/**
 * Vide container et lui ajoute div.errors et div.j3pContainer qu’il retourne
 * @param container
 */
export function normalizeContainer (container: HTMLElement | string): NormalizedContainers {
  const ct = typeof container === 'string' ? document.getElementById(container) : container
  if (ct == null) throw Error('Container manquant')
  if (!document.body.contains(ct)) throw Error('Container invalide')
  empty(ct)
  const mainContainer = addElement(ct, 'div', { className: 'j3pContainer' })
  const errorsContainer = addElement(ct, 'div', { className: 'errors' })
  return { mainContainer, errorsContainer }
}

/**
 * Affecte les attributs attrs à elt
 * @param elt
 * @param attrs
 * @returns {void}
 */
export function setAttrs<Tag extends TagName> (elt: Elt<Tag>, attrs: ObjOfString): void {
  if (attrs == null) return
  for (const [attr, value] of Object.entries(attrs)) {
    if (typeof value === 'string') elt.setAttribute(attr, value)
    else console.error(Error(`Un attribut doit être une string, ${typeof value} incorrect pour ${attr} (${value})`))
  }
}

/**
 * Affecte les props à l’élément (en gérant les spécificités des HTMLElement, avec qq vérifs
 * Les valeurs undefined sont ignorées, les valeurs null suppriment la propriété.
 */
export function setProps<Tag extends TagName> (elt: Elt<Tag>, props: EltOptions<Tag>): void {
  if (props == null) return
  for (const [prop, value] of Object.entries(props)) {
    if (value == null) continue
    // style peut pas être affecté directement (lecture seule), il faut affecter ses propriétés
    if (prop === 'style') {
      if (typeof value !== 'object') {
        console.error(Error(`valeur incorrecte pour un style (${typeof value})`))
        continue
      }
      setStyle(elt, value)
    } else if (prop === 'id') {
      // on passe toujours par getNewId pour ne jamais mettre dans le dom un id qui existe déjà
      // si ça n’existe pas ce sera utilisé tel quel, sinon il y aura un suffixe numérique et ça va râler en console
      if (typeof value === 'string') elt.id = getNewId(value, true)
      else console.error(Error(`type incorrect pour un id, ignoré ${typeof value} ${value}`))
    } else if (prop === 'class') {
      console.warn('La propriété correspondante à l’attribut class d’un élément est className et non class (rectifié)')
      if (typeof value === 'string') elt.setAttribute('class', value)
      else console.error(Error(`type incorrect pour une classe css, ignoré ${typeof value} ${value}`))
    } else if (/^[oO]n/.test(prop)) {
      if (typeof value === 'function') {
        console.warn('Il faudrait utiliser addEventListener plutôt que ' + prop)
        // @ts-ignore si c’est une fonction, on a déjà le warn en console.
        elt[prop] = value
      } else {
        console.error(Error(`Propriété ${prop} invalide (${typeof value})`))
      }
    } else if (['name', 'title'].includes(prop)) {
      // pour ceux-là faut passer par un setAttribute, sinon ça ne fait rien du tout (au moins sous chrome sur un div)
      if (typeof value === 'string') elt.setAttribute(prop, value)
      else console.error(Error(`type incorrect pour ${prop}, ignoré ${typeof value} ${value}`))
    } else {
      // @ts-ignore dans le pire des cas, on ajoute une propriété qui sert à rien à un élément du DOM.
      elt[prop] = value
    }
  }
}

/**
 * Ajoute ou retire les classes ok|ko de elt suivant isOk
 * @param elt
 * @param isOk
 */
export function setOk (elt: HTMLElement, isOk: boolean) {
  if (isOk) {
    elt.classList.add('ok')
    elt.classList.remove('ko')
  } else {
    elt.classList.add('ko')
    elt.classList.remove('ok')
  }
}

/**
 * Affiche une erreur en modale
 */
export function showError (error: Error | unknown): void {
  if (error instanceof Error) console.error(error)
  const modal = new Modal({ title: 'Une erreur est survenue' })
  const content = error instanceof Error ? error.message : String(error)
  modal.addElement('p', { content, className: 'error' })
  modal.show()
}

/**
 * Un getElementById amélioré, qui râle en console s’il ne trouve pas l’élément (ou s’il le trouve et qu’il devrait pas), mais aussi si y’en a plusieurs
 * Il ne devrait jamais y avoir besoin d’utiliser cette fonction car on ne devrait jamais imposer d’id.
 * Si vous avez besoin d’un élément gardez une référence dessus (avec `const elt = addElement(…)`)
 * sans ajouter d’id aux éléments.
 * @obsolete
 * @param id
 * @param [shouldExists=true] Avec undefined|true il râle s’il ne trouve pas, avec false il râle s’il trouve, et avec tout le reste il reste muet (donc passer null pour le faire taire indépendamment du résultat)
 * @return {HTMLElement|null}
 */
export function getElement (id: string | HTMLElement, shouldExists: boolean = true): void | Node | null {
  if (id instanceof HTMLElement) {
    console.error(Error('j3pElement appelé avec un HTMLElement'))
    return id
  }
  if (typeof id !== 'string') return console.error(TypeError('getElement ne traite que des string'))
  // on utilise querySelectorAll plutôt que getElementById parce que l’on veut savoir s’il y en a plusieurs
  // Mais y’a des sections qui affectent n’importe quoi comme id (qui commence par un chiffre ou contient des caractères invalides comme des parenthèses)
  // => ça plante avec du `xxx is not a valid selector.`, d’où le test préalable (y’avait un try/catch dans le commit 604d3284)
  // cf https://www.w3.org/TR/1999/REC-html401-19991224/types.html#type-name
  let elts: NodeList | HTMLElement[]
  if (isValidId(id)) {
    elts = Array.from(document.querySelectorAll('#' + id))
  } else {
    const elt = document.getElementById(id)
    elts = elt ? [elt] : [] // si y’en a pas on veut une liste vide et pas [null]
  }

  if (elts.length) {
    if (elts.length > 1) console.error(Error(`Il y a ${elts.length} éléments dans le dom avec l’id ${id} !`))
    else if (shouldExists === false) console.error(Error(`Il y a déjà un élément d’id ${id} dans le DOM`))
    return elts[0]
  }

  // rien trouvé…
  if (!elts.length && shouldExists === true) {
    // Il faut le signaler
    console.error(Error('Aucun élément ' + id + ' dans le document courant'))
  }
  return null
}
