/** @module lib/utils/css */

import { PlainObject } from 'src/lib/types'
import { isDomElement } from 'src/lib/utils/dom/main'
import { isPlainObject } from './object'
import { hyphenToCamel } from './string'

import type { StyleObject, StylePropWritable } from 'src/lib/utils/dom/dom'

const reShortHexCssColorCapture = /^#([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])$/
const reLongHexCssColorCapture = /^#([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/
// en css4 le séparateur espace est accepté
const reRgbaCapture = /^ *rgba? *\( *(\d+)[ ,]+(\d+)[ ,]+(\d+)(:?[ ,]+(\d+))? *\) *$/

/**
 * Des propriétés css connues mais qui ne sont pas dans la liste retournée par getComputedStyle
 */
const knownCssProps: string[] = [
  /**
   * Liste des propriétés css raccourcies (que getComputedStyle ne retourne pas), _initCssProps en aura besoin
   * Cf https://developer.mozilla.org/fr/docs/Web/CSS/Shorthand_properties
   * @private
   */
  'animation', 'background', 'border', 'border-bottom', 'border-color', 'border-left', 'border-radius', 'border-right', 'border-style', 'border-top', 'border-width', 'column-rule', 'columns', 'flex', 'flex-flow', 'font', 'grid', 'grid-area', 'grid-column', 'grid-row', 'grid-template', 'list-style', 'margin', 'offset', 'outline', 'overflow', 'padding', 'place-content', 'place-items', 'place-self', 'text-decoration', 'transition',
  // et faut ajouter aussi
  'white-space'
]

/**
 * Liste des propriétés css (hyphen-case) avec leur correspondance camelCase
 * (has permet de savoir si on a une propriété hyphen-case valide)
 * @private
 */
const cssProps: Map<string, StylePropWritable> = new Map()
/**
 * Liste des propriétés css (camelCase) avec leur correspondance hyphen-case
 * (has permet de savoir si on a une propriété camelCase valide)
 * @private
 */
const styleProps: Map<StylePropWritable, string> = new Map()

/**
 * Affecte cssProps et styleProps
 * @private
 */
function _initCssProps (): void {
  // on prend les propriétés raccourcies + toutes celles du navigateur (sauf celles qui commencent par un tiret, spécifiques à ce navigateur)
  const props = knownCssProps.concat(Array.from(getComputedStyle(document.body)).filter(p => !p.startsWith('-') && !['length', 'parentRule'].includes(p)))
  for (const cssProp of props) {
    if (cssProp.includes('-')) {
      const styleProp = hyphenToCamel(cssProp) as StylePropWritable
      cssProps.set(cssProp, styleProp)
      styleProps.set(styleProp, cssProp)
    } else {
      // pas besoin de conversion y’a pas de -, idem css et style
      cssProps.set(cssProp, cssProp as StylePropWritable)
      styleProps.set(cssProp as StylePropWritable, cssProp)
    }
  }
}

/**
 * Liste des propriétés css qui sont des dimensions (donc un nombre avec une unité css de taille)
 * @type {string[]}
 * @private
 */
const dimensionStyleProps: string[] = ['top', 'left', 'bottom', 'right', 'fontSize', 'width', 'height', 'minWidth', 'minHeight', 'maxWidth', 'maxHeight']

/**
 * Transforme une string de déclaration css (attribut style, avec attributs css en hyphen-case)
 * en objet style (propriété style de l’élément html, avec propriétés en camelCase)
 * @param {string} cssString
 * @return {Object}
 */
export function getStyleFromCssString (cssString: string): StyleObject {
  if (typeof cssString !== 'string') throw Error('paramètre invalide')
  const style = {} as StyleObject
  for (const def of cssString.split(';')) {
    if (/^\s*$/.test(def)) continue // on ignore un truc vide ou composé uniquement d’espaces
    const chunks = /^ *([a-zA-Z-]+) *: *(.+)$/.exec(def)
    if (chunks != null && (chunks[1] != null && chunks[2] != null)) {
      const prop = hyphenToCamel(chunks[1]) as StylePropWritable
      const value = chunks[2].trim() // trim pour les espaces de fin
      // @ts-ignore Pas très grave si la propriété n’existe pas
      if (value.length > 0) style[prop] = value
      else console.error(Error(`valeur vide pour ${prop} (c’était dans « ${cssString} »)`))
    } else {
      console.error(Error('portion de déclaration css invalide : ' + def + ' (c’était dans « ' + cssString + ' »)'))
    }
  }
  return style
}

/**
 * Retourne un objet style nettoyé (propriétés en camelCase toutes valides, le reste est ignoré), éventuellement vide
 * @param {Object|string} style
 * @return {Object}
 */
export function getCleanStyle (style: PlainObject | string): StyleObject {
  if (typeof style === 'string') style = getStyleFromCssString(style)
  const cleanStyle = {} as StyleObject
  if (isPlainObject(style)) {
    for (let [prop, value] of Object.entries(style)) {
      // on ignore silencieusement les trucs louches
      if (typeof value !== 'string' && typeof value !== 'number') {
        if (value != null && typeof value !== 'function') {
          // faut pas abuser
          console.error(Error(`valeur invalide pour ${prop} (${typeof value} ${String(value)}) => ignorée`))
        }
        continue
      }
      try {
        const cleanProp = enforceStyleProp(prop)
        if (dimensionStyleProps.includes(cleanProp)) {
          const dim = getCssDimension(value)
          if (dim) cleanStyle[cleanProp] = dim
          // sinon on ne fait rien, getCssDimension a déjà râlé
        } else {
          if (typeof value === 'number') value = String(value)
          cleanStyle[cleanProp] = value as string
        }
      } catch (error) {
        console.error(error)
      }
    }
  } else {
    console.error(Error(`style invalide (${typeof style})`))
  }
  return cleanStyle
}

/**
 * Affecte chaque propriété de style à svgElt (conversion éventuelle en camelCase mais on ne vérifie pas si les propriétés existent)
 * @param {SVGElement} svgElement
 * @param {Object|string} style
 */
export function setSvgStyle (svgElement: SVGElement, style: StyleObject | string): void {
  if (typeof style === 'string') style = getStyleFromCssString(style)
  if (isPlainObject(style)) {
    for (let [prop, value] of Object.entries(style)) {
      prop = hyphenToCamel(prop)
      // @ts-ignore Si la propriété n’existe pas, c’est pas grave, ça ne casse rien, si c’est une propriété readonly, ça plantera
      svgElement.style[prop] = value
    }
  } else {
    console.error(Error(`style invalide (${typeof style})`))
  }
}

/**
 * Retourne la couleur sous la forme #rrggbb
 * @param {string} cssColor
 * @return {string}
 * @throws {TypeError} si cssColor n’était pas une couleur valide
 */
export function getHexaFromCssColor (cssColor: string): string {
  if (reLongHexCssColorCapture.test(cssColor)) return cssColor
  const chunks: RegExpExecArray | null = reShortHexCssColorCapture.exec(cssColor)
  if (chunks != null) return '#' + chunks.slice(1).map(v => v + v).join('')
  const rgbValues: number[] = getRgbaFromCssColor(cssColor).slice(0, 3)
  return '#' + rgbValues.map(dec => dec.toString(16)).join('')
}

/**
 * Retourne une couleur en hexa à partir d’un tableau de valeurs rgb
 * @param {number[]} values les valeurs rgb (avec éventuellement alpha en 4e)
 * @return {string} La couleur #rrggbb
 * @throws {TypeError} si values ne contenait pas 3 nombres valides (entiers positifs < 256)
 */
export function getHexaFromRgbaValues (values: number[]): string {
  if (!Array.isArray(values) || ![3, 4].includes(values.length)) throw TypeError('valeurs rgb invalides')
  const rgbValues: number[] = values.slice(0, 3) // on vire l’éventuel 4e valeur
  if (rgbValues.some(n => !Number.isInteger(n) || n < 0 || n > 255)) throw TypeError('valeurs rgb invalides')
  return '#' + rgbValues.map(dec => dec.toString(16)).join('')
}

/**
 * Converti une couleur css en valeurs rgb
 * Attention, ne marche pas s’il y a des fonctions css dans les arguments de rgba
 * @param {string} cssColor de la forme #rgb ou #rrggbb ou rgb(r, g, b) ou rgba(r, g, b, a).
 * @return {number[]} Les 3 valeurs rgb dans un tableau
 * @throws {TypeError} si cssColor n’est pas une couleur valide
 */
export function getRgbaFromCssColor (cssColor: string): number[] {
  const abort: () => never = () => {
    throw TypeError(`invalid color ${cssColor}`)
  }
  if (typeof cssColor !== 'string') abort()
  // on regarde d’abord les couleurs à 3 chars
  let chunks: RegExpExecArray | null | string[] = reRgbaCapture.exec(cssColor)
  if (chunks != null) {
    // on a déjà les valeurs rgb, on cast en number et vérifie que c’est valide
    const rgbValues = chunks.slice(1, 4).map(Number).filter(v => Number.isInteger(v) && v > -1 && v < 256)
    if (rgbValues.length === 3) {
      // faut vérifier le a éventuel
      if (chunks.length === 5) {
        const alpha = Number(chunks[4])
        if (Number.isFinite(alpha) && alpha >= 0 && alpha <= 1) {
          rgbValues.push(alpha)
          return rgbValues
        }
        console.error(Error(`valeur alpha ${alpha} invalide => 1 imposé`))
      }
      // on ajoute 1 comme valeur pour la couche alpha (idem opacité)
      rgbValues.push(1)
      return rgbValues
    }
    abort()
  }
  chunks = reShortHexCssColorCapture.exec(cssColor)
  if (chunks != null) {
    // on double chaque char, comme le fait css
    chunks = chunks.map(chunk => chunk + chunk)
  } else {
    chunks = reLongHexCssColorCapture.exec(cssColor)
  }
  if (chunks == null) throw TypeError(`invalid color ${cssColor}`)
  const rgbValues: number[] = chunks.slice(1, 4).map(v => parseInt(v, 16))
  // toujours 1 pour alpha
  rgbValues.push(1)
  return rgbValues
}

/**
 * Vérifie que la propriété de style est connue (hyphen case accepté mais générera un warning)
 * @param prop
 * @return la propriété de style connue (camelCase)
 * @throws {Error} si prop n’est pas une propriété de style connue
 */
export function enforceStyleProp (prop: string): StylePropWritable {
  if (styleProps.size === 0) _initCssProps()
  // si c'est déjà une StylePropWritable y'a rien à faire
  if (styleProps.has(prop as StylePropWritable)) return prop as StylePropWritable
  // on vérifie cssProp en hyphen-case
  const styleProp = cssProps.get(prop) as (StylePropWritable
    | undefined)
  if (styleProp == null) {
    throw Error(`${prop} n’est pas une propriété de style connue`)
  }
  console.warn(`${prop} était une propriété css mais pas une propriété de style => convertie en ${styleProp}`)
  return styleProp
}

/**
 * Ajoute px si value est un nombre sans unité (sauf 0 conservé sans unité), retourne value si c’est déjà une longueur css avec une unité valide, string vide sinon
 * @param {number|string} value
 * @return {string} une string valide pour une dimension en css ou une chaîne vide
 */
export function getCssDimension (value: number | string): string {
  // pour 0 on ajoute pas d’unité
  if (value === 0 || value === '0') return '0'
  if (typeof value === 'number' && isFinite(value)) return String(value) + 'px' // le cast number => string va faire .2 => 0.2px et +4 => 4px)
  if (typeof value === 'string') {
    // mot-clés sans dimension acceptés
    if (['inherit', 'unset', 'initial'].includes(value)) return value
    if (/^(min|max|fit)-content$/.test(value)) return value
    // nombre avec unité valide
    if (/^[+-]?[0-9.]+(px|em|rem|ex|ch|vw|vh|vmin|vmax|%|pt|pc|in|Q|mm|cm)$/.test(value)) return String(value)
    // nombre en string sans unité, on ajoute px
    if (/^[+-]?[0-9.]+$/.test(value)) return String(value) + 'px'
  }
  // sinon ça retourne '', mais on râle
  console.error(Error(String(value) + ' n’est pas une dimension css valide (cf https://drafts.csswg.org/css-values-3/#lengths)'))
  return ''
}

/**
 * teste si cssColor est bien une couleur css valide, hexa ou rgb() ou rgba()
 * @param {any} cssColor
 * @return {boolean}
 */
export function isCssColor (cssColor: string): boolean {
  if (typeof cssColor !== 'string') return false
  return [reShortHexCssColorCapture, reLongHexCssColorCapture, reRgbaCapture].some(re => re.test(cssColor))
}

/**
 * Affecte le style à l’élément (en vérifiant l’intégrité de style.
 * Convertit d’éventuelles erreurs hyphen-case/camelCase en le signalant en warning
 * @param {string|HTMLElement|SVGElement} elt
 * @param {Object|string} style
 * @throws {Error} si elt n’est pas un HTMLElement ou un SVGElement
 */
export function setStyle (elt: HTMLElement | SVGElement, style: PlainObject | string): void {
  if (!isDomElement(elt)) throw Error('element invalide')
  for (const [prop, value] of Object.entries(getCleanStyle(style))) {
    // @ts-ignore Si la propriété n’existe pas, c’est pas grave, ça ne casse rien (normalement, style est 'nettoyé' des trucs qui n’existent pas).
    elt.style[prop] = value
  }
}
