/** @module lib/utils/string */
import { testValidName } from './regexp'
import { randomInt } from './random'

const majuscules = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const tabMaj = Array.from(majuscules)
const blockDelimiters: Record<string, string> = {
  '(': ')',
  '[': ']',
  '{': '}',
  '<': '>',
  '«': '»'
}

/**
 * Ajoute une lettre majuscule à la liste fournie (et retourne cette lettre)
 * Si toutes les majuscules sont déjà dans la liste, ajoute un suffixe numérique
 * @param {string[]} lettres
 * @return {string}
 * @throws {TypeError} si lettres n’est pas un tableau, contient autre chose que des majuscules ou a une longueur > 25
 */
export function addMaj (lettres: string[]): string {
  if (!Array.isArray(lettres)) throw TypeError('Il faut passer un array')
  let pioche = tabMaj.filter(l => !lettres.includes(l))
  if (pioche.length === 0) {
    // faut ajouter un suffixe numérique
    let suf = 2
    while (pioche.length === 0) {
      pioche = tabMaj.map(l => `${l}${suf}`).filter(nom => !lettres.includes(nom))
      suf++
    }
  }
  const index = randomInt(0, pioche.length - 1)
  const lettre = pioche[index]
  if (lettre == null) throw Error('y’a un bug dans addMaj') // sinon ts est pas content
  lettres.push(lettre)
  return lettre
}

/**
 * Transforme une chaîne camelCase en hyphen-case (donc lower case)
 * @param {string} str
 * @return {string}
 * @throws {TypeError} si str n’est pas une string
 */
export function camelToHyphen (str: string): string {
  if (typeof str !== 'string') throw TypeError('paramètre invalide')
  if (/^[a-z-]+$/.test(str)) return str
  return str
    .replace(/^[A-Z]/, l => l.toLowerCase()) // si le premier caractère est une majuscule on le passe en minuscule
    .replace(/([A-Z])/g, '-$1') // on insère un tiret devant les autres majuscules, passées en minuscule
}

/**
 * Passe en majuscule la première lettre du mot (ça peut être une phrase)
 * @param {string} mot
 * @return {string}
 */
export const capitalize = (mot: string): string => mot.length > 0 ? mot.charAt(0).toUpperCase() + mot.substring(1) : ''

/**
 * Retourne value si c’est une string non vide, defaultValue sinon
 * @param {string}value
 * @param {string} defaultValue
 * @throws {Error} si ni value ni defaultValue ne sont des string non vide
 * @return {string}
 */
export function getNonEmptyString (value: unknown, defaultValue: string): string {
  if (typeof value === 'string' && value.length > 0) return value
  if (typeof defaultValue !== 'string' || defaultValue.length === 0) throw Error('valeur par défaut invalide')
  return defaultValue
}

/**
 * Retourne le message de l’erreur ou un cast en string
 * @param {Error|any} error
 * @returns {string}
 */
export function getErrorMessage (error: any, { withStack = false } = {}): string {
  if (error instanceof Error) return error.message + (withStack ? '\n' + String(error.stack) : '')
  return String(error)
}

/**
 * Retourne l’index du caractère fermant correspondant à celui étant à index de chaine
 * @param {string} chaine la chaîne à traiter
 * @param {number} index indice du caractère ouvrant dont on cherche le fermant
 * @return {number} l’indice du caractère fermant, -1 si pas trouvée
 */
export function getIndexFermant (chaine: string, index: number): number {
  const ouvrant = chaine.charAt(index)
  const fermant = blockDelimiters[ouvrant]
  if (fermant == null) {
    console.error(Error(`Le caractère ${ouvrant} n’est pas un caractère ouvrant géré ici`))
    return -1
  }
  let somme = 1
  index++
  while (index < chaine.length) {
    const ch = chaine.charAt(index)
    if (ch === ouvrant) {
      somme++
    } else if (ch === fermant) {
      somme--
    }
    if (somme === 0) return index
    index++
  }
  return -1 // renvoie -1 si pas trouvé
}

/**
 * Retourne le contenu délimité par le caractère ouvrant fourni ( ou [ ou { ou «
 * @param chaine
 * @param charOuvrant
 * @param offset
 */
export function getFirstDelimitedContent (chaine: string, charOuvrant: string, offset = 0): string {
  const fermant = blockDelimiters[charOuvrant]
  if (fermant == null) throw Error(`caractère ouvrant non géré : ${charOuvrant}`)
  const indexOuvrant = chaine.indexOf(charOuvrant, offset)
  if (indexOuvrant === -1) return ''
  const indexFermant = getIndexFermant(chaine, indexOuvrant)
  if (indexFermant > indexOuvrant) return chaine.substring(indexOuvrant + 1, indexFermant)
  return ''
}

/**
 * Retourne l’index de la première occurence de searched dans st à partir de startIndex, en excluant les occurences contenues entre des parenthèses.
 * Retourne -1 si pas trouvé
 * @param st
 * @param searched
 * @param startIndex
 */
export function getFirstIndexParExcluded (st: string, searched: string, startIndex: number): number {
  let firstFound = st.indexOf(searched, startIndex)
  // si ça existe pas inutile d’aller plus loin
  if (firstFound === -1) return -1
  // on parcours st pour ignorer ce qui est entre parenthèses
  let i = startIndex
  while (i < st.length) {
    if (st.charAt(i) === '(') {
      i = getIndexFermant(st, i) + 1
      // si y’a pas de fermant on arrête là
      if (i === 0) return -1
    } else {
      if (i === firstFound) return i
      if (i > firstFound) {
        // celui qu’on avait trouvé était entre parenthèses, on regarde si y’a un suivant
        firstFound = st.indexOf(searched, i)
        if (firstFound === -1) return -1
      }
      i++
    }
  }
  return -1
}

/**
 * Transforme une chaîne hyphen-case en camelCase (un éventuel - au début sera retiré)
 * @param {string} str
 * @return {str}
 * @throws {TypeError} si str n’est pas une string
 */
export function hyphenToCamel (str: string): string {
  if (typeof str !== 'string') throw TypeError('paramètre invalide')
  if (/^[a-zA-Z]+$/.test(str)) return str // rien à faire y’a pas d’espace ni de tiret, que des lettres
  return str
    .toLowerCase() // minuscules
    .replace(/^-/, '') // vire un éventuel '-' au début (-mod-border-radius => mozBorderRadius)
    // puis on vire les '-' et passe en majuscule le caractère qui suit
    .replace(/-(.)/g, (...args) => args[1].toUpperCase())
}

/**
 * Retourne true si str est une string non vide
 * @param str
 * @returns {boolean}
 */
export const isStringNotEmpty = (str: unknown): str is string => typeof str === 'string' && str.length > 0

/**
 * Retourne true si file est une string qui se termine en .(cjs|mjs|js|ts)
 */
export const isLikeJsFile = (file: string): boolean => typeof file === 'string' && /\.(j|t|cj|mj)s$/.test(file)

/**
 * Retourne true si l’identifiant est valide (doit vérifier /^[a-zA-Z][a-zA-Z0-9_:.-]*$/, Cf [spec]{@link https://www.w3.org/TR/1999/REC-html401-19991224/types.html#type-name})
 * Râle en console et retourne false sinon
 * @param {string} id L’id à tester
 * @return {boolean}
 */
export function isValidId (id: string): boolean {
  if (testValidName.test(id)) return true
  console.error(Error(`"${id}" n’est pas un identifiant valide`))
  return false
}

/**
 * Retourne la chaîne inversée
 * @param s
 */
export function revert (s: string): string {
  let out = ''
  for (const c of s) out = `${c}${out}`
  return out
}
