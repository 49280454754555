/** @module lib/utils/regexp */

/**
 * Regexp de test d’un nom valide (pour un id ou une classe css), d’après https://www.w3.org/TR/1999/REC-html401-19991224/types.html#type-name
 */
export const testValidName = /^[a-zA-Z][a-zA-Z0-9_:.-]*$/
/**
 * Regexp de test d’un intervalle fermé d’entiers (sans capture des bornes)
 */
export const testIntervalleFermeEntiers = /^\[-?(?:[1-9][0-9]*|0);-?(?:[1-9][0-9]*|0)]$/
/**
 * Regexp de test d’un intervalle d’entiers (sans capture des bornes, ouvert ou fermé)
 */
export const testIntervalleEntiers = /^[[\]]-?(?:[1-9][0-9]*|0);-?(?:[1-9][0-9]*|0)[[\]]$/
/**
 * Capture les bornes d’un intervalle d’entiers
 */
export const captureIntervalleFermeEntiers = /^\[(-?[1-9][0-9]*|-?0);(-?[1-9][0-9]*|-?0)]$/
/**
 * Regexp de test d’un intervalle d’entiers (sans capture des bornes)
 */
export const testIntervalleFermeEntiersPositifs = /^\[(?:[1-9]+[0-9]*|0);(?:[1-9][0-9]*|0)]$/
/**
 * Capture les bornes d’un intervalle d’entiers
 */
export const captureIntervalleFermeEntiersPositifs = /^\[([1-9][0-9]*|0);([1-9][0-9]*|0)]$/
/**
 * Test un intervalle fermé de décimaux (séparateur . ou ,), sans capture des bornes
 */
export const testIntervalleFermeDecimaux = /^\[-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?;-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?]$/
/**
 * Test un intervalle (ouvert ou fermé) de décimaux (séparateur . ou ,), sans capture des bornes
 */
export const testIntervalleDecimaux = /^[[\]]-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?;-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?[[\]]$/
/**
 * Capture les bornes d’un intervalle de décimaux (séparateur . ou ,)
 */
export const captureIntervalleFermeDecimaux = /^\[(-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?);(-?(?:[1-9][0-9]*|0)(?:[.,][0-9]+)?)]$/
/**
 * Test un intervalle de décimaux (séparateur .), sans capture
 */
export const testIntervalleFermeDecimauxPoint = /^\[-?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?;-?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?]$/
/**
 * Capture les bornes d’un intervalle de décimaux (séparateur .)
 */
export const captureIntervalleFermeDecimauxPoint = /^\[(-?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?);(-?(?:[1-9][0-9]*|0)(?:\.[0-9]+)?)]$/
/**
 * Test des bornes d’un intervalle de décimaux (séparateur ,), sans capture
 */
export const testIntervalleFermeDecimauxVirgule = /^\[-?(?:[1-9][0-9]*|0)(?:,[0-9]+)?;-?(?:[1-9][0-9]*|0)(?:,[0-9]+)?]$/
/**
 * Capture les bornes d’un intervalle de décimaux (séparateur ,)
 */
export const captureIntervalleFermeDecimauxVirgule = /^\[(-?(?:[1-9][0-9]*|0)(?:,[0-9]+)?);(-?(?:[1-9][0-9]*|0)(?:,[0-9]+)?)]$/

/**
 * Transforme une restriction de string en RegExp
 * @param {string} restriction
 * @param {Object} [options]
 * @param {string} [options.flags=''] flags à ajouter à la regex retournée
 * @return {RegExp} La regexp correspondante à chars (nettoyée) entre crochets, avec échappements nécessaires
 */
export function convertRestriction (restriction: string, { flags = '' } = {}): RegExp {
  // on accepte nullish ou string vide, qui veut dire pas de restriction
  if ([null, undefined, ''].includes(restriction)) return /./
  if (typeof restriction !== 'string') throw Error(`Restriction de type ${typeof restriction} invalide (string exigée)`)
  // on échappe les 3 caractères ^-] qui sont les seuls à poser pb entre crochets, cf https://developer.mozilla.org/fr/docs/Web/JavaScript/Guide/Regular_Expressions
  // Pour le - il faut vérifier le caractère qui précède et celui qui suit, ça peut déjà être une classe valide, et sinon faut l’échapper
  // (?<!y)x Correspond à x uniquement si x n’est pas précédé par y
  // cette regexp fonctionne très bien sur chrome/firefox, mais ça casse qqchose pour le chargement sur iPad
  // restriction = restriction.replace(/(?<!\\)([\^\-\]])/g, '\\$1')
  // => on capture le caractère précédent pour vérifier que c’est pas un \,

  // mais avant faut retirer un éventuel double \\ (un antislash déjà échappé), et récupérer les \x valides
  let cleanRestriction = ''
  if (restriction.includes('\\\\')) {
    // double slash échappé
    cleanRestriction += '\\\\'
    restriction = restriction.replace(/\\\\/g, '')
  }
  if (restriction.includes('-')) {
    // faut regarder le caractère avant / après, pour savoir si c’est une classe de caractères valide
    const ranges = restriction.match(/([a-z]-[a-z]|[A-Z]-[A-Z]|[0-9]-[0-9])/g)
    if (ranges != null) {
      // on vire la classe valide de restriction pour la mettre dans cleanRestriction
      for (const range of ranges) {
        cleanRestriction += range
        restriction = restriction.replace(range, '')
      }
    }
  }
  // on échappe tous les ]-^ non précédés d’un \ qui restent
  // on peut pas mettre de ? après la 1re capture sinon ça peut doubler des \, => on ajoute une espace au début qu’on vire ensuite
  cleanRestriction += (' ' + restriction)
    .replace(/([^\\])([\]^-])/g, '$1\\$2') // échappe les chars ]-^ non précédés de \
    .substring(1)
  return new RegExp(`[${cleanRestriction}]`, flags)
}
