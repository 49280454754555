/** @module lib/utils/object */

import type { PlainObject } from 'src/lib/types'
import { getErrorMessage } from './string'

interface EnforceShapeOptions {
  errorPrefix?: string
  errorSuffix?: string
  falsyAllowed?: boolean
  falsyProps?: string[]
  undefAllowed?: boolean
  undefProps?: string[]
}

// objet avec des clés quelconques dont chaque valeur est une string ou une liste de strings
interface HashOfStringOrStringList {
  [index: string]: string | string[]
}

export interface ObjOfUnknown {
  [key: string]: unknown
}

export interface ObjOfString {
  [key: string]: string
}

/**
 * Valide le format d’un objet (throw si invalide) (à priori inutile en ts, sauf lorsqu’un ts est inclus par un js…)
 * @param {Object} obj l’objet à valider
 * @param {Object} props les propriétés qu’il doit avoir, propriété en clé et type en valeur (un array s’il y a plusieurs type pour cette prop)
 * @param {Object} [options]
 * @param {string} [options.errorPrefix] Un éventuel préfixe à ajouter aux messages d’erreur
 * @param {string} [options.errorSuffix] Un éventuel suffixe à ajouter aux messages d’erreur
 * @param {boolean} [options.falsyAllowed] Passer true si toutes les propriétés peuvent être falsy
 * @param {string[]} [options.falsyProps] Passer la liste des propriétés qui peuvent être falsy (les boolean peuvent toujours l'être, inutile de les mettre ici)
 * @param {boolean} [options.undefAllowed] Passer true si toutes les propriétés sont facultatives (mais si elles existent elles doivent être du bon type)
 * @param {string[]} [options.undefProps] Passer la liste des propriétés facultatives
 * @throws {Error} si le format n’est pas correct
 * @returns {undefined} si ok
 */
export function enforceShape (obj: unknown, props: HashOfStringOrStringList, {
  errorPrefix = '',
  errorSuffix = '',
  falsyAllowed = false,
  falsyProps = [],
  undefAllowed = false,
  undefProps = []
}: EnforceShapeOptions = {}): void {
  // normalisation de la string d’erreur éventuelle
  if (errorPrefix != null && !errorPrefix.endsWith(' ')) errorPrefix += ' '
  if (errorSuffix != null && !errorSuffix?.startsWith(' ')) errorSuffix = ` ${errorSuffix}`
  if (obj == null || typeof obj !== 'object') throw Error(`${errorPrefix}Il faut passer un objet non null${errorSuffix}`)
  // on se simplifie le code plus loin en complétant toujours falsyProps (qui est toujours un tableau)
  if (!Array.isArray(falsyProps)) falsyProps = falsyAllowed ? Object.keys(props) : []
  if (!Array.isArray(undefProps)) undefProps = undefAllowed ? Object.keys(props) : []
  const errors: string[] = []
  for (const [prop, type] of Object.entries(props)) {
    // @ts-ignore si obj[prop] n’existe pas eh bien on gère, c’est justement le but de la fonction
    const value = obj[prop]
    if (typeof value === 'undefined') {
      if (!undefProps.includes(prop)) errors.push(`propriété ${prop} manquante`)
      break
    }
    // eslint-disable-next-line valid-typeof
    if ((Array.isArray(type) && type.includes(typeof value)) || typeof value === type) {
      if (type !== 'boolean' && !value && !falsyProps.includes(prop)) {
        errors.push(`La propriété ${prop} a le type requis (${String(type)}) mais une valeur falsy : ${String(value)}`)
      }
    } else {
      errors.push(`propriété ${prop} est de type ${typeof value}, ${String(type)} requis`)
    }
  }
  if (errors.length > 0) throw Error(errorPrefix + errors.join(' ET ') + errorSuffix)
}

/**
 * Évalue jsExpression et retourne sa valeur, mais dans un contexte isolé (nettement plus sécure qu’un simple eval).
 * Attention, si jsExpression contient l’appel d’une fct js non définie dans jsExpression ça va planter, seuls Math et Number sont dispos.
 * (donc eval() mais aussi parseInt() ou console.log() ou n’importe quelle fct js)
 * Merci à {@link https://blog.risingstack.com/writing-a-javascript-framework-sandboxed-code-evaluation/} pour la trame
 * @param {string} jsExpression
 * @throws {Error} si jsExpression n’est pas du js valide ou s’il contient un appel à une fonction js non définie dans jsExpression
 * @return {unknown} La valeur de jsExpression évalué
 */
export function safeEval (jsExpression: string): unknown {
  if (['number', 'boolean', 'object', 'undefined'].includes(typeof jsExpression)) return jsExpression
  if (typeof jsExpression !== 'string') throw TypeError(`type ${typeof jsExpression} invalide pour safeEval`)
  // @see https://blog.risingstack.com/writing-a-javascript-framework-sandboxed-code-evaluation/
  // On utilise pas le weakMap qu’il propose car on n’évalue chaque expression qu’une seule fois
  // (son exemple est pour compiler le code d’une fonction qui sera appelée de nombreuses fois)
  // On crée le proxy qui va intercepter tous les appels de variables/fonctions
  const proxyHandler = {
    // si has retourne toujours true, le `in sandbox` sera toujours true et le code évalué ne remontera jamais au scope global
    // car toutes les variables sont vues comme existante dans le scope local (mis avec le with)
    has: () => true,
    // le Symbol.unscopables est là pour les propriétés qui ne sont pas contraintes par le with => on empêche ainsi de remonter au scope global pour celles-là
    get: (target: Record<string, unknown>, key: string | typeof Symbol.unscopables) => key === Symbol.unscopables ? undefined : target[key]
  }
  // on autorise Math et Number dans le code js évalué, mais rien d’autre
  const sandboxProxy = new Proxy({ Math, Number }, proxyHandler)
  // et on wrap l’expression avec un with pour limiter le scope au sandbox qui sera passé à la fct
  const src = 'with (sandbox) { return ' + jsExpression + '}'
  // ça ressemble à de l’eval, mais ici c’est très encadré
  // eslint-disable-next-line no-new-func
  const runner = new Function('sandbox', src)
  return runner(sandboxProxy)
}

/**
 * Retourne true si prop est une propriété propre de obj (pas héritée)
 * @param {Object|undefined} obj
 * @param {string} prop
 * @return {boolean}
 */
export const hasProp = (obj: object | undefined, prop: string): boolean => typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, prop)

/**
 * Retourne le dernier élément d’un tableau
 * @param {Array} list
 * @return {*} Le dernier élément, ou undefined si list n’était pas un tableau non vide
 */
export const lastElt = (list: Array<unknown>) => (Array.isArray(list) && list.length) ? list[list.length - 1] : undefined

/**
 * Retourne true si obj est un plain object avec au moins une propriété non undefined (éventuellement null)
 * @param obj
 * @return {boolean}
 */
export const isPlainObjectNotEmpty = (obj: any): obj is PlainObject => isPlainObject(obj) && Object.values(obj).filter(v => v !== undefined).length > 0

/**
 * Retourne true si obj est un objet "standard" (non null), donc false sur Array, Date, Rexexp, etc.
 * @param {*} obj
 * @return {boolean}
 */
export const isPlainObject = (obj: any): obj is PlainObject => Object.prototype.toString.call(obj) === '[object Object]'

/**
 * Idem JSON.stringify mais sans planter sur les ref circulaires (dont la valeur est remplacée par le message d’erreur de la ref circulaire)
 * @param {any} obj
 * @param {number} [indent=0] nb d’espace d’indentation
 * @return {string}
 */
export function stringify (obj: any, indent = 0): string {
  if (!Number.isInteger(indent) || indent < 0) indent = 0
  const indentStr = ' '.repeat(indent)
  try {
    // on traite les erreurs d’abord, pour avoir message+stack, car JSON.stringify retourne {}
    if (obj instanceof Error) return `${indentStr}${obj?.stack?.split('\n').join(indentStr + '\n') ?? String(obj)}`
    // ça peut planter en cas de ref circulaire
    return (indent > 0) ? JSON.stringify(obj, null, indent) : JSON.stringify(obj)
  } catch (error) {
    // on a un objet avec ref circulaire
    const pile: string[] = []
    Object.entries(obj).forEach(([prop, value]) => {
      // on veut le même comportement que JSON.stringify qui omet les valeurs undefined et les fct
      // et met du {} pour regexp et function
      if (!['undefined', 'function'].includes(typeof value)) {
        try {
          pile.push(`"${prop}":${JSON.stringify(value, null, indent)}`)
        } catch (error) {
          const errorValue: string = getErrorMessage(error).replace(/\n/g, '\\n').replace(/"/g, '\\"')
          pile.push(`"${prop}":"${errorValue}"`)
        }
      }
    })
    const spacer = (indent > 0) ? '\n' + ' '.repeat(indent) : ''
    const sep = ',' + spacer
    return `{${spacer}${pile.join(sep)}${indent > 0 ? '\n' : ''}}`
  }
}

/**
 * Copie de la fonction /legacy/core/functions.js#j3PClone() pour la V2
 * Clone un objet (il n’y aura pas les méthodes du prototype de l’objet source,
 * mais s’il y a des propriétés qui sont des fonctions elles seront conservées,
 * sans clonage sur leurs éventuelles propriétés, idem pour d’éventuelles fcts d’un tableau)
 * @param {Object} obj
 * @param {boolean} [noDeep=false] passer true pour ne cloner que le premier niveau (shallow copy, pas de deep clone)
 * @return {Object} ou obj inchangé si c'était pas un object
 */
export function cloneObject<T> (obj: T, noDeep: boolean): unknown {
  // on passe par une fonction imbriquée pour avoir une trace de l’appel initial en cas de boucle infinie
  // (ça arrive si une valeur d’une propriété de l’objet est l’objet lui-même)
  const getClone = (obj: object | unknown): object | unknown => {
    // si c’est null ou pas object on ne fait rien
    if (!obj || typeof obj !== 'object') return obj
    if (Array.isArray(obj)) return noDeep ? [...obj] : obj.map(getClone)
    if (!isPlainObject(obj)) return obj
    // c’est un objet "standard" (ni regexp ni date ni…)
    const clone: Record<string, unknown> = {}
    for (const [prop, value] of Object.entries(obj)) {
      clone[prop] = noDeep ? value : getClone(value)
    }
    return clone
  }
  try {
    return getClone(obj)
  } catch (error) {
    console.error(Error('Objet trop profond (probablement une référence circulaire)'), error)
    return obj
  }
}
